import React, { useState, useEffect, useCallback, useRef } from 'react';
import { defaultRSSPrompts } from './nip105-client';
import './PromptOptionsComponent.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const defaultLabel = "Customize Prompt (Optional)"

const PromptOptionsComponent = ({
  initialOption,
  onOptionChange,
  onCustomPromptChange,
  showOptionsOnOpen = false,
  preselectedPrompt = null,
  labelText = defaultLabel,
  customContext = ""
}) => {
  const [customPromptVisible, setCustomPromptVisible] = useState(false);
  const [customPrompts, setCustomPrompts] = useState({});
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const segmentedControlRef = useRef(null);
  const [customPromptInput, setCustomPromptInput] = useState('');

  const getPromptValue = useCallback(() => {
    console.log(`getPromptValue`);
    console.log(`preselectedPrompt:`, preselectedPrompt);
    console.log(`selectedOption:`, selectedOption);
    if (customPrompts[selectedOption]) {
      return customPrompts[selectedOption];
    } else if (preselectedPrompt && defaultRSSPrompts[preselectedPrompt]) {
      return defaultRSSPrompts[preselectedPrompt];
    } else if (defaultRSSPrompts[selectedOption]) {
      return defaultRSSPrompts[selectedOption];
    }
    return '';
  }, [preselectedPrompt, selectedOption, customPrompts]);


  const isTranscript = (preselectedPrompt === 'Transcript');

  useEffect(() => {
    if (initialOption && initialOption !== selectedOption) {
      setSelectedOption(initialOption);
      onOptionChange(initialOption);
    }
  }, [initialOption, onOptionChange, selectedOption]);

  useEffect(() => {
    if (showOptionsOnOpen) {
      setCustomPromptVisible(true);
    }
    const currentPromptValue = getPromptValue();
    onCustomPromptChange(currentPromptValue);
  }, [showOptionsOnOpen, onCustomPromptChange]);
  

  useEffect(() => {
    const checkScroll = () => {
      if (segmentedControlRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = segmentedControlRef.current;
        setShowLeftScroll(scrollLeft > 0);
        setShowRightScroll(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  const handleScroll = useCallback(() => {
    if (segmentedControlRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = segmentedControlRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft < scrollWidth - clientWidth - 1);
    }
  }, []);

  useEffect(() => {
    setCustomPromptInput(getPromptValue());
  }, [getPromptValue, selectedOption]);
  

  const scrollLeft = () => {
    if (segmentedControlRef.current) {
      segmentedControlRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (segmentedControlRef.current) {
      segmentedControlRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const handleOptionChange = useCallback((newOption) => {
    console.log(`handleOptionChange:`, newOption);
    setSelectedOption(newOption);
    onOptionChange(newOption);
  }, [onOptionChange]);

  const handleCustomPromptChange = useCallback((event) => {
    setCustomPromptInput(event.target.value);
  }, []);

  const handleCustomPromptBlur = useCallback(() => {
    setCustomPrompts((prevPrompts) => ({
      ...prevPrompts,
      [selectedOption]: customPromptInput,
    }));
    onCustomPromptChange(customPromptInput);
  }, [customPromptInput, selectedOption, onCustomPromptChange]);
  
  

  const handleCustomPromptToggle = useCallback(() => {
    setCustomPromptVisible(prev => !prev);
  }, []);

  return (
    <div className="prompt-options-container">
      {preselectedPrompt === null && (
        <div className="segmented-control-wrapper">
          {showLeftScroll && (
            <button className="scroll-button left" onClick={scrollLeft}>
              <ChevronLeft size={24} />
            </button>
          )}
          <div 
            className="segmented-control" 
            ref={segmentedControlRef}
            onScroll={handleScroll}
          >
            {Object.keys(defaultRSSPrompts).map((opt) => (
              <button
                key={opt}
                onClick={() => handleOptionChange(opt)}
                className={selectedOption === opt ? 'active' : ''}
              >
                {opt}
              </button>
            ))}
          </div>
          {showRightScroll && (
            <button className="scroll-button right" onClick={scrollRight}>
              <ChevronRight size={24} />
            </button>
          )}
        </div>
      )}
      
      {!isTranscript && (
        <div> 
          <div className={`custom-prompt-header ${customContext}`} onClick={handleCustomPromptToggle} style={{marginBottom:'16px'}}>
            <div className={`arrow ${customPromptVisible ? 'down' : 'right'}`}></div>
            <span>{labelText}</span>
          </div>
          {preselectedPrompt && customPromptVisible && (
            <div> 
              <h2 style={{fontSize:'18px'}}>Customize Your Prompt (Optional)</h2>
            </div>
          )}
        </div>
      )}

      {customPromptVisible && !isTranscript && (
          <textarea
          value={customPromptInput}
          onChange={handleCustomPromptChange}
          onBlur={handleCustomPromptBlur}
          placeholder="Enter your custom prompt here..."
          className={`prompt-options-textarea ${customContext}`}
          style={{ marginBottom: '16px' }}
        />        
      )}
    </div>
  );
};

export default React.memo(PromptOptionsComponent);